<template>
  <div class="page-box">
    <base-screen>
      <a-form-model ref="query" layout="inline" :model="query" @submit.native.prevent @submit="handleQuery">
        <a-form-model-item prop="userPhone" label="登录账号">
          <a-input allow-clear v-model="query.userPhone" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="userNo" label="用户编号">
          <a-input allow-clear v-model="query.userNo" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="shopName" label="店铺名称">
          <a-input allow-clear v-model="query.shopName" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="changeType" label="变动类型">
          <a-select style="width: 120px;" allow-clear v-model="query.changeType" placeholder="请选择">
            <a-select-option v-for="item in changeTypes" :value="item.value" :key="item.value">{{item.label}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="operationTime" label="操作时间">
          <a-range-picker v-model="query.operationTime" />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleQuery">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>

    <base-table
      bordered
      :columns="columns"
      :dataSource="data"
      :pagination="page"
      :rowKey="x => x.userAccountChangeBillId"
      :loading="loading"
      @change="handleTableChange"
    >
      <template #user="ctx">
        <div class="user-card">
          <img v-if="ctx.headImg" :src="ctx.headImg" alt="" />
          <p>{{ctx.userName}}</p>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch } from '@vue/composition-api'
import { getSession } from '@/utils/session'
import { SHOPID } from '@/constants'
import { as } from '@/api'

export default {
  name: 'PageChargeAccountHistory',
  setup () {
    const getInitQuery = () => ({
      userName: '',
      userPhone: '',
      shopName: '',
      operationTime: [],
      changeType: void 0,
      shopId: getSession(SHOPID),
    })
    const query = reactive(getInitQuery())

    const page = reactive({
      current: 1,
      size: 10,
      total: 0
    })

    const data = ref([])
    const loading = ref(false)
    async function getTableData () {
      loading.value = true
      const res = await as.getChargeAsHistoryPage({
        ...query,
        ...page,
        ...(query.operationTime.length ? {
          startTime: query.operationTime[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          endTime: query.operationTime[1].endOf('day').format('YYYY-MM-DD HH:mm:ss'),
          operationTime: void 0
        } : null)
      })
      loading.value = false
      if (res.code === '00000') {
        page.total = res.page.total
        data.value = res.data
      }
    }
    onMounted(() => {
      getTableData()
    })

    function handleQuery () {
      page.current = 1
      getTableData()
    }

    function handleReset () {
      Object.assign(query, getInitQuery())
      handleQuery()
    }

    function handleTableChange ({ current }) {
      page.current = current
      getTableData()
    }

    let t
    const columns = [
      {
        title: '用户',
        scopedSlots: {
          customRender: 'user'
        }
      },
      {
        title: '登录账号',
        dataIndex: 'userPhone'
      },
      {
        title: '用户编号',
        dataIndex: 'userNo'
      },
      {
        title: '店铺',
        dataIndex: 'shopName'
      },
      {
        title: '变动类型',
        dataIndex: 'changeType',
        customRender: x => (t = changeTypes.find(u => u.value === x)) && t.label
      },
      {
        title: '变动金额(元)',
        dataIndex: 'amount',
      },
      {
        title: '变动后金额(元)',
        dataIndex: 'afterAmount',
      },
      {
        title: '操作说明',
        dataIndex: 'remark'
      },
      {
        title: '操作人',
        dataIndex: 'createUser'
      },
      {
        title: '操作时间',
        dataIndex: 'createTime'
      },
      {
        title: '订单编号',
        dataIndex: 'orderNo'
      },
    ]

    const changeTypes = [
      { value: 1, label: '充值' },
      { value: 2, label: '支付' },
      { value: 7, label: '退回' },
    ]

    return {
      query,
      data,
      page,
      loading,
      handleQuery,
      handleReset,
      columns,
      handleTableChange,
      getTableData,
      changeTypes,
    }
  }
}
</script>
